
@font-face {
  font-family: "Wallop";
  src: url('./fonts/Wallop-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Wallop Semi bold";
  src: url('./fonts/Wallop-SemiBold.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Wallop Medium";
  src: url('./fonts/Wallop-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Wallop Bold";
  src: url('./fonts/Wallop-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}
.person-lit {
  color:white;
}
.triangle {
  color:white;
  filter: brightness(0%);
}

 .top-bar-tab{
  color: #ffffff80;
  cursor:pointer;
}
 .top-bar-container{
  display: flex;
  justify-content: space-between;
  color:white;
  font-size:24px;
  padding:20px;
 
  @media screen and (max-width: 576px) {
    padding:0px 20px;
    font-size:20px;
    & img{
      width:75%;
    }
  }
  @media screen and (max-height: 576px) {
    padding:0px 20px;
    font-size:20px;
    & img{
      width:75%;
    }
  }
}
.topbar{
  top:0px;
  left: 0px;
  background-color: #1a1e28;
  width:100%;
  padding: 10px 0px;
 
}
.problem-wrap{
  position: absolute;
  top:3px;
  right: 0px;
  padding: 10px 0px;
  
}
.font-f{
  color: #FFFFFF;
}
.twinwrap {
  display: flex;
  flex-direction: row;
  column-gap: 1vh;
  margin-right: 1vh;
}
.twinbtns22 {
  border-radius: 2em;
  background-color: #9afee0;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 18vh;
  height: 6vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #9afee0;
  margin-top: 7px;
}
.twinbtns0 {
  border-radius: 2em;
  background-color: #9afee0;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 18vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #9afee0;
}
.twinbtns1 {
  border-radius: 2em;
  background-color: #30343D;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 18vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #30343D;
}
.twinbtns2 {
  border-radius: 2em;
  background-color: #30343D;
  padding-bottom: 0vh;
  padding-left: 1vh;
  padding-right: 1vh;
  width: 18vh;
  height: 4vh;
  font-size: 1.5vh;
  font-weight: 600;
  border: #30343D;
}
.twinbtns-loggedin{
  border-radius: 2em;
  background-color: #30343D;
  padding-left: 1vh;
  padding-right: 1vh;
  font-size: 1.5vh;
  font-weight: 600;
  margin-top: 3px;
  width: 18vh;
  height:4vh;
  border: #30343D;
}
.nomarkers::visited {
  text-decoration: none;
  color: black;
}
.nomarkers {
  text-decoration: none;
}
.twin0{
  color:#FEFFFA;
}
.twin4{
  color: black;
}
.twin01{
  color:#FEFFFA;
  margin-top: 2px;
}
.twin1{
  color:#FEFFFA;
}
.footer {
  width: 100%;
  background: #1C1E23;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#6A7280;
  padding: 50px 30px;
  font-family: "Wallop";
  font-size:18px;
  font-weight: 700;
}
.loadingPage {
  width:100%;
  background: #1C1E23;
}
.section_container{
  max-width:1320px;
  margin:0 auto;
  padding:10px;
  @media screen and (max-width: 1200px) {
    max-width: 1140px;
  }
  @media screen and (max-width: 992px) {
    max-width: 960px;
  }
  @media screen and (max-width: 768px) {
    max-width: 720px;
  }
  @media screen and (max-width: 576px) {
    max-width: 540px;
  }
  @media screen and (max-width: 420px) {
    max-width: 100%;
  }
  
  
}
.cards-promo-wrap {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-self: center;
  justify-content: center;

}
.cards-promo{
  align-self: center;
  margin-top: 30px;
  margin-bottom: -30px;

}
.btn{
  background: #9AFEE5;
  border-radius: 30px;
  padding: 10px 20px;
  color:black;
  font-family: "Wallop";
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    padding: 6px 15px;
  }
}
.signup-btn{
  background: #9AFEE5;
  border-radius: 30px;
  padding: 10px 20px;
  color:black;
  font-family: "Wallop";
  font-size: 18px;
  line-height: 20px;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    padding: 6px 15px;
  }
}




.marginbottom_50_responsive{
    margin-bottom: 50px !important;
    @media screen and (max-width: 576px) {
      margin-bottom: 5px !important;
    }
}
.marginbottom_30_responsive{
  margin-bottom: 30px !important;
  @media screen and (max-width: 576px) {
    margin-bottom: 10px !important;
  }
}

.padding-top-100{
  padding-top:100px !important;
}
.padding-tb-none{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.padding-20{
  padding:20px;
}
.margin-bottom-10{
  margin-bottom: 10px !important;
}
.margin-bottom-15{
  margin-bottom: 15px !important;
}
.margin-top-10{
  margin-top: 10px !important;
}
.margin-top-15{
  margin-top: 15px !important;
}
.margin-top-30{
  margin-top: 30px !important;
}
.margin-left-5{
  margin-left: 5px !important;
}
.margin-left-10{
  margin-left: 10px !important;
}
.margin-left-20{
  margin-left: 20px !important;
}
.margin-left-30{
  margin-left: 30px !important;
}
.margin-left-minus-15{
  margin-left: -15px !important;
}
.margin-left-minus-20{
  margin-left: -15px !important;
}
.margin-bottom-minus-25{
  margin-bottom: -25px !important;
  @media screen and (max-width: 768px) {
    margin-bottom: -10px !important;
  }
}
.margin-left-50-percent{
  margin-left: 50% !important;
}
.line-height-40{
  line-height: 40px !important;
}
.line-height-50{
  line-height: 50px !important;
}
.line-height-20{
  line-height: 20px !important;
}
.line-height-30{
  line-height: 30px !important;
}
.top-left{
  position: absolute;
  top: 0px;
  left: 2px;
}
.top-right{
  position:absolute;
  top: 15px;
  right: 0px;
}
.display-flex{
  display: flex;
  display: -webkit-flex;
}
.flex-align-center{
  align-items: center;
}
.flex-align-start{
  align-items: flex-start !important;
}
.flex-align-end{
  align-items: flex-end !important;
  @media screen and (max-width: 576px) {
    align-items: center !important;
    justify-content: center !important;
  }
}
.flex-justify-center{
  justify-content: center;
}
.flex-space-between{
  justify-content: space-between;
}
.flex-column{
  flex-direction: column;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex-space-around{
  justify-content: center;
  justify-content: space-evenly;
  justify-content: space-around;
}


.font-size-24{
  font-size: 24px !important;

  @media screen and (max-width: 768px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
  }
}
.font-size-22{
  font-size: 22px !important;

  @media screen and (max-width: 768px) {
    font-size: 18px !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 14px !important;
  }
}
.font-size-20{
  font-size: 20px !important;

  @media screen and (max-width: 768px) {
    font-size: 16px !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 12px !important;
  }
}
.font-size-36{
  font-size: 18px !important;


}
.font-size-40{
  font-size: 40px !important;

  @media screen and (max-width: 768px) {
    font-size: 36px !important;
  }
  @media screen and (max-width: 576px) {
    font-size: 32px !important;
  }
}
.font-size-18{
  font-size: 18px !important;
  @media screen and (max-width: 576px) {
    font-size: 16px !important;
  }
}
.font-size-17{
  font-size: 17px !important;
  @media screen and (max-width: 576px) {
    font-size: 13px !important;
  }
}
.font-size-16{
  font-size: 16px !important;

  @media screen and (max-width: 576px) {
    font-size: 13px !important;
  }
}
.font-size-14{
  font-size: 14px !important;

  @media screen and (max-width: 576px) {
    font-size: 11px !important;
  }
}
.font-size-12{
  font-size: 12px !important;

  @media screen and (max-width: 576px) {
    font-size: 10px !important;
  }
}
.font-left{
  text-align: start !important;

}
.font-bold{
  font-family: "Wallop Bold";
}

.font-semibold{
  font-family: "Wallop Semi bold";
}

.font-medium{
  font-family: "Wallop Medium";
}

.border-radius-15{
  border-radius: 15px !important;
}
.border-radius-25{
  border-radius: 25px !important;
}
.border-radius-100{
  border-radius: 100% !important;
}

.position-absolute{
  position:absolute;
}

.position-relative{
  position:relative;
}

.top-5{
  top:5px;
}

.left-5{
  left:5px;
}

.width-100{
  width:100%;
}
.height-100{
  height:100%;
}
.max-width-100{
  max-width:100%;
}
.height-100vh{
  height: 100vh !important;
}
.color-blue{
  color: #8df3db; 
}
.color-white{
  color: white; 
}
.color-gray{
  color: #b1b1b1;
}

.btn{
  border-radius: 25px;
  font-size:16px;
  line-height: 18px;
  font-weight: 800 !important;
  font-family: "Wallop Bold";
  @media screen and (max-width: 768px) {
    font-size:12px;
    letter-spacing: -0.8px;
  }
}
.btn-blue{
  background: #9AFEE5;
  color: #000000;
  padding: 5px 20px;
  @media screen and (max-width: 768px) {
    padding: 5px 10px;
  }
}
.btn-dark{
  background: #464A51;
  color: #FFFFFF;
  padding: 5px 20px;
  @media screen and (max-width: 768px) {
    padding: 5px 10px;
  }
}

.text-inline{
  text-overflow: ellipsis;
  white-space: nowrap;

  overflow: hidden;
}
.emoji-inline{
  white-space: nowrap;
}
.cursor-pointer{
  cursor: pointer !important;
}

.font-weight-600{
  font-weight: 600;
}

.profile-img-box{
  width:200px;
  height: 200px;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
}

.emoji-commentary{
  display:flex;
  flex-direction: row;
  column-gap: 8px;
  align-content: center;
  align-items: center;
}
.emojis-wrap{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 2em;
  border: 0.1em solid;
  border-color: white;
  column-gap: 4px;
  margin-top: .05em;
}
.emojis-wrap-background{
  background-color: white;
  border-radius: 2em;
  border: 0.05em solid;
  border-color: white;
  line-height: .92;
}
.card-image{
  aspect-ratio: 1/1;
  object-fit: cover;
  margin-top: -20px;
}
.cardtextwrap {
  display: flex;
  flex-direction: column;
  line-height: 1.7;
  justify-content: center;
  text-overflow: ellipsis;

}

.emojis-wrap-case1{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background-color: white;
  border-radius: 2em;
  border: 0.1em solid;
  border-color: white;
  column-gap: 4px;
}

.log-out{
  align-self: center;
  margin-top: -8px;
  margin-bottom: 8px;
}
.spotify-logo-trackcard{
  margin-top: -15px;
}

.next-line-space{
  white-space: initial;
}
.productscreenshotwrap{
  margin-top: 18px;
}
.tooltip-cards{
  align-self: center;
  width: 60%;
  height:60%;
  margin-bottom: -1px;
  margin-left: -3px;
}
.productscreenshot{
  margin-top: 50px;
  margin-bottom: -80px;
}
.drop-shadow{
  -webkit-filter: drop-shadow(2px 2px 2px #222);
  filter: drop-shadow(2px 2px 2px #222);
  width: 70%;
  height: 70%;
}
.bs-person{
  margin-top: 6px;
  margin-bottom: -3px;
}
.stat-empty-mob{
  top:0;
  left:0;

}
.stat-mob{
  width: 140px;
}
.statswrap{
  width: 140px;
  margin-top: -5px;
}
.username-mob{
  align-self: center;
}
.profile-area-mob{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-img-box-mob{
  width:200px;
  height: 200px;
  background-position: center;
  background-size: cover;
  border-radius: 100%;
  margin-bottom: 8px;
}
.copy-sec{
  display: flex;
  flex-direction: column;
}
.margin-left-link{
  margin-left: 40% !important;
}
.copie{
  margin-bottom: -2px;
  margin-left: 5px;
}


.twinbtns-loggedin-mob{
  border-radius: 2em;
  background-color: #30343D;
  padding-left: 1vh;
  padding-right: 1vh;
  font-weight: 600;
  font-size: 10px;
  width: 100px;
  position: relative;
  top: -7.8px;
  height: 30px;
  border: #30343D;
}
.bs-person-mob{
  margin-bottom: -4px;
}

.log-out-mob{
  align-self: center;
}


.editTextMob{
  font-size: 10px;
  margin-top: 10px;
  cursor:pointer;
}
